.footer__wrapper {
  position: relative;
  height: auto;
  background-color: rgba(33, 33, 33, 0.5);
  padding: 80px 120px;
  padding-bottom: 10px;
}

.footer__self_bio__wrapper {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
}

.footer__self_bio__container {
  display: flex;
  align-items: center;
  gap: 20px;
}
.footer__self_bio__container__img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
}
.footer__self_bio__container__title {
  font-size: 2.1rem;
  margin-bottom: 5px;
}
.footer__self_bio__container__description {
  font-size: 20px;
}

.footer__self_bio__container__social_container {
  display: flex;
  color: #fff;
  gap: 20px;
  margin: 30px 0;
  list-style: none;
}
.footer__self_bio__container__social_link {
  color: white;
  font-size: 1.5rem;
}
.footer__contact__title {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 2.3rem;
  margin-bottom: 20px;
}

@media only screen and (max-width: 900px) {
  .footer__wrapper {
    padding: 40px;
    padding-bottom: 10px;
  }

  .footer__self_bio__container {
    flex-direction: column;
  }

  .footer__contact__title {
    font-size: 2rem;
  }

  .footer__self_bio__container__social_container {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .footer__self_bio__container__text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer__self_bio__wrapper {
    flex-direction: column;
  }
  .footer__contact__links {
    flex-direction: column;
  }
}
