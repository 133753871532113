.hero {
  flex: 1;
  background: rgba(0, 0, 0, 0.1);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.hero .hero__container {
  text-align: center;
  margin-top: 100px;
}

.hero .hero__title {
  font-size: 6.5rem;
  text-align: center;
  font-family: "Poppins", sans-serif;
  line-height: 100%;
  font-weight: 800;
}

.hero .hero__container .hero__desc {
  font-family: "Poppins", sans-serif;
  display: block;
  margin: auto;
  margin-top: 30px;
  font-size: 1rem;
  width: 60%;
}

.button__cta__list {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.hero .hero_cta {
  padding: 8px 16px;
  background: #35353505;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: white;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: all 0.5s ease;
  font-family: "Poppins", sans-serif;
}

.hero_cta.cta__1 {
  background-color: #ca49b4;
}

.hero__cta--discord p {
  font-family: "Poppins", sans-serif;
}

.hero__cta--discord:hover {
  background-color: #5662ea;
}

.background__assets__container {
  position: relative;
  top: 0;
  left: 0;
}

.background__assets {
  /* height: 100%; */
  position: absolute;
  overflow: hidden;
  width: 500px;
  height: 500px;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */

  z-index: -1;
  filter: blur(100px);
  animation: rotate_bg 10s ease-in infinite forwards;
}

@keyframes rotate_bg {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.circle {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  /* z-index: -100; */
}

.circle--purple {
  background-color: rgb(186, 0, 186);
  top: 50%;
  left: 50%;
}

.circle--blue {
  background-color: rgb(49, 182, 226);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gradient__text {
  background-image: linear-gradient(
    90deg,
    rgba(49, 182, 226, 1) 0%,
    rgba(186, 0, 186, 1) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  /* font-weight: bold; */
}

.dynamic_text {
  font-size: 1.3rem;
}

@media only screen and (max-width: 600px) {
  .dynamic_text {
    font-size: 1rem;
  }

  .hero .hero__title {
    font-size: 3.4rem;
  }

  .hero .hero__container .hero__desc {
    font-size: 0.8rem;
    width: 80%;
  }
}
