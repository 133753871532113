.projects__container {
  margin-top: 50px;
}

.project__Card {
  max-width: 400px;
  background-color: rgba(57, 57, 57, 0.23);
  border-radius: 10px;
  padding: 10px;
  border: 1px solid rgb(77, 29, 180);
  min-height: 500px;
}

.project__Card .project__image {
  object-fit: contain;
  width: 100%;
}

.swiper.swiper-initialized.swiper-horizontal {
  height: 500px;
}

.projects__layout {
  width: 150%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}