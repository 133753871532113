.form_input {
  margin: 15px 0px;
}

.form_input label {
  font-size: 14px;
  margin-bottom: 10px;
}

.classic__input {
  padding: 13px 10px;
  width: 100%;
  margin-top: 10px;
  background-color: transparent;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  color: #fff;
}

.input_flex__g {
  width: 100%;
  gap: 40px;
}

@media only screen and (max-width: 600px) {
  .input_flex__g {
    flex-direction: column;
    gap: 0px;
  }
}
