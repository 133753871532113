.navbar {
  display: flex;
  padding: 10px 25px;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  background: #000;
  position: sticky;
  top: 0;
  bottom: 0;
  z-index: 10000;
}

.navbar__title {
  font-size: 28px;
}

.navbar__list {
  display: flex;
  align-items: center;
  list-style: none;
  width: fit-content;
  gap: 50px;
}
.navbar__list li a {
  text-decoration: none;
  font-size: 16px;
  color: white;
}

.navbar__cta {
  padding: 6px 18px;
  border-radius: 5px;
  color: white;
  /* Nav bar ko on scroll dene ka plan tha merea  */
  /* matlab fixed ? */
  font-size: 14px;
  font-weight: medium;
  background: #ca49b4;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.203);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  cursor: pointer;
}

.navbar__cta:focus {
  scale: 0.9;
}

.mobile__nav {
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: rgb(0, 0, 0, 0.9);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  list-style: none;
  transition: all 1s cubic-bezier(0.48, 0, 0, 1);
}
.mobile__nav--hide {
  left: -100%;
  opacity: 0;
}

.mobile__nav--show {
  left: 0;
  opacity: 1;
}

.mobile__nav li a {
  text-decoration: none;
  font-size: 18px;
  color: white;
}

@media only screen and (max-width: 600px) {
  .navbar__list li:not(:last-child) {
    display: none;
  }

  .navbar__list {
    width: max-content;
  }
}
